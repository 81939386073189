/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull test_js_sdk'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 23
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/streamlineplan/Streamline/implementation/test_js_sdk)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'streamline'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} streamline
 */
export const ApiKey = {
  streamline: 'df99649136ee5a0d7be498bde64d94a7'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '23',
    branch: 'main',
    source: 'test_js_sdk',
    versionId: '94ebcfb6-57fe-482a-b257-98509b88e2f4'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class AbcVisibilityInTreeChanged {
  constructor(properties) {
    this.event_type = 'ABC Visibility in Tree Changed';
    this.event_properties = properties;
  }
}

export class AddConnectionButtonClicked {
  constructor(properties) {
    this.event_type = 'Add Connection Button Clicked';
    this.event_properties = properties;
  }
}

export class AggregationQueriesConfirmed {
  constructor() {
    this.event_type = 'Aggregation Queries Confirmed';
  }
}

export class CategoriesInTreeChanged {
  constructor() {
    this.event_type = 'Categories in Tree Changed';
  }
}

export class ConnectionConfigurationStepCompleted {
  constructor(properties) {
    this.event_type = 'Connection Configuration Step Completed';
    this.event_properties = properties;
  }
}

export class ConnectionConfigured {
  constructor(properties) {
    this.event_type = 'Connection Configured';
    this.event_properties = properties;
  }
}

export class ConnectionCreated {
  constructor(properties) {
    this.event_type = 'Connection Created';
    this.event_properties = properties;
  }
}

export class ConnectionDeleted {
  constructor(properties) {
    this.event_type = 'Connection Deleted';
    this.event_properties = properties;
  }
}

export class ConnectionSettingsButtonClicked {
  constructor(properties) {
    this.event_type = 'Connection Settings Button Clicked';
    this.event_properties = properties;
  }
}

export class ConnectorMultiplicityChosen {
  constructor(properties) {
    this.event_type = 'Connector Multiplicity Chosen';
    this.event_properties = properties;
  }
}

export class CreateProjectClicked {
  constructor(properties) {
    this.event_type = 'Create Project Clicked';
    this.event_properties = properties;
  }
}

export class DemandTableOverridden {
  constructor(properties) {
    this.event_type = 'Demand Table Overridden';
    this.event_properties = properties;
  }
}

export class DistortionsVisibilityChanged {
  constructor(properties) {
    this.event_type = 'Distortions Visibility Changed';
    this.event_properties = properties;
  }
}

export class EditConnectionSettingsButtonClicked {
  constructor() {
    this.event_type = 'Edit Connection Settings Button Clicked';
  }
}

export class ExampleOpened {
  constructor(properties) {
    this.event_type = 'Example Opened';
    this.event_properties = properties;
  }
}

export class ForecastReportImported {
  constructor(properties) {
    this.event_type = 'Forecast Report Imported';
    this.event_properties = properties;
  }
}

export class ForecastingParametersOverridden {
  constructor(properties) {
    this.event_type = 'Forecasting Parameters Overridden';
    this.event_properties = properties;
  }
}

export class InactiveItemsVisibilityChanged {
  constructor(properties) {
    this.event_type = 'Inactive Items Visibility Changed';
    this.event_properties = properties;
  }
}

export class InventoryTableImported {
  constructor(properties) {
    this.event_type = 'Inventory Table Imported';
    this.event_properties = properties;
  }
}

export class InventoryTableOverridden {
  constructor(properties) {
    this.event_type = 'Inventory Table Overridden';
    this.event_properties = properties;
  }
}

export class PlannedOrdersCreateOrders {
  constructor(properties) {
    this.event_type = 'Planned orders > Create orders';
    this.event_properties = properties;
  }
}

export class PlannedOrdersExported {
  constructor(properties) {
    this.event_type = 'Planned Orders Exported';
    this.event_properties = properties;
  }
}

export class ProjectCreated {
  constructor() {
    this.event_type = 'Project Created';
  }
}

export class ProjectFirstImportClicked {
  constructor() {
    this.event_type = 'Project First Import Clicked';
  }
}

export class ProjectReimport {
  constructor() {
    this.event_type = 'Project reimport';
  }
}

export class ProjectWasRestricted {
  constructor(properties) {
    this.event_type = 'Project Was Restricted';
    this.event_properties = properties;
  }
}

export class TalkToExpertButtonClicked {
  constructor(properties) {
    this.event_type = 'Talk To Expert Button Clicked';
    this.event_properties = properties;
  }
}

export class TenantActivationConfirmed {
  constructor() {
    this.event_type = 'Tenant Activation Confirmed';
  }
}

export class TreeOrderChanged {
  constructor(properties) {
    this.event_type = 'Tree Order Changed';
    this.event_properties = properties;
  }
}

export class UpgradeStreamlineWindowViewed {
  constructor(properties) {
    this.event_type = 'Upgrade Streamline Window Viewed';
    this.event_properties = properties;
  }
}

export class UserLoggedIn {
  constructor() {
    this.event_type = 'User Logged In';
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {string} [properties.Account Plan] User's account plan: free or enterprise.  Users from same tenant have same account plan
   * @param {string} [properties.Frontegg User Id] Property has no description in tracking plan.
   * @param {string} [properties.Tenant] Property has no description in tracking plan.
   * @param {string} [properties.tenant_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * ABC Visibility in Tree Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/ABC%20Visibility%20in%20Tree%20Changed)
   *
   * User turns on/off showing of ABC analysis in Demand Tree
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.Checkbox Status] Shows value that was just set in checkbox. Can be used in multiple events
   * @param {boolean} properties.checkboxStatus Shows value that was just set in checkbox. Can be used in multiple events
   * @param {EventOptions} [options] Options for this track call.
   */
  abcVisibilityInTreeChanged(properties, options) {
    return this.track(new AbcVisibilityInTreeChanged(properties), options);
  }

  /**
   * Add Connection Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Add%20Connection%20Button%20Clicked)
   *
   * User clicked 'Add connection' button when creating Connector 2.0 project
   *
   * @param {Object} properties The event's properties.
   * @param {'import'|'exportOrders'|'exportReports'} properties.connectorPurpose Purpose of connector: for import or for export
   * @param {EventOptions} [options] Options for this track call.
   */
  addConnectionButtonClicked(properties, options) {
    return this.track(new AddConnectionButtonClicked(properties), options);
  }

  /**
   * Aggregation Queries Confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Aggregation%20Queries%20Confirmed)
   *
   * User clicked 'Confirm' button on Aggregtion queries page
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  aggregationQueriesConfirmed(options) {
    return this.track(new AggregationQueriesConfirmed(), options);
  }

  /**
   * Categories in Tree Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Categories%20in%20Tree%20Changed)
   *
   * User changed demand tree categories visibility (turned on/off a category)
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  categoriesInTreeChanged(options) {
    return this.track(new CategoriesInTreeChanged(), options);
  }

  /**
   * Connection Configuration Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Connection%20Configuration%20Step%20Completed)
   *
   * User passed one step of creating/editing of connection.
   *
   * Event should be bind to click on primary button 'Next' on connection creation page
   *
   *
   *
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.connectionStepName Name of configuration step that shown in progress bar  ![](ampl://278495/e9da0ac5-6baf-452b-9f16-169601fa5c6b.png)  Possible values:   * Upload link  * Match sheets  * Connect  * Pull data  * Transform  * Set up  Values of this property just need to distinguishable
   * @param {EventOptions} [options] Options for this track call.
   */
  connectionConfigurationStepCompleted(properties, options) {
    return this.track(new ConnectionConfigurationStepCompleted(properties), options);
  }

  /**
   * Connection Configured
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Connection%20Configured)
   *
   * User succesfully completed all steps of creation/configuring of project and clicked 'Finish' button
   *
   * Event is sent when connection becomes completed
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.connectorName User-provided name of created connection  Can be used in analytics to identify different connections of one user by their names
   * @param {string} properties.connectorType Type of created connection: name of ERP, 'DB', 'Transactional spreadsheet' etc.   Values of this property should be easily distinguishable
   * @param {EventOptions} [options] Options for this track call.
   */
  connectionConfigured(properties, options) {
    return this.track(new ConnectionConfigured(properties), options);
  }

  /**
   * Connection Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Connection%20Created)
   *
   * User created uninitialized connection and chose its type and name
   *
   * Event should be bind to click on 'Create' button in connection creation form
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.connectorName User-provided name of created connection  Can be used in analytics to identify different connections of one user by their names
   * @param {string} properties.connectorType Type of created connection: name of ERP, 'DB', 'Transactional spreadsheet' etc.   Values of this property should be easily distinguishable
   * @param {boolean} [properties.isExistingConnector] Indicates that new export connection was created using settings of existing import connection
   * @param {EventOptions} [options] Options for this track call.
   */
  connectionCreated(properties, options) {
    return this.track(new ConnectionCreated(properties), options);
  }

  /**
   * Connection Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Connection%20Deleted)
   *
   * User deleted one of the connections from project
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.connectorName User-provided name of created connection  Can be used in analytics to identify different connections of one user by their names
   * @param {string} properties.connectorType Type of created connection: name of ERP, 'DB', 'Transactional spreadsheet' etc.   Values of this property should be easily distinguishable
   * @param {EventOptions} [options] Options for this track call.
   */
  connectionDeleted(properties, options) {
    return this.track(new ConnectionDeleted(properties), options);
  }

  /**
   * Connection Settings Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Connection%20Settings%20Button%20Clicked)
   *
   * User clicked 'Connection settings' button from three-dot menu
   *
   *
   *
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.connectorName User-provided name of created connection  Can be used in analytics to identify different connections of one user by their names
   * @param {string} properties.connectorType Type of created connection: name of ERP, 'DB', 'Transactional spreadsheet' etc.   Values of this property should be easily distinguishable
   * @param {EventOptions} [options] Options for this track call.
   */
  connectionSettingsButtonClicked(properties, options) {
    return this.track(new ConnectionSettingsButtonClicked(properties), options);
  }

  /**
   * Connector Multiplicity Chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Connector%20Multiplicity%20Chosen)
   *
   * User chose single or multiple connection type before creating project
   *
   *
   *
   *
   * @param {Object} properties The event's properties.
   * @param {'single'|'multiple'} properties.connectorMultiplicity Which type of connection user chose for creating a new project: single or multiple
   * @param {EventOptions} [options] Options for this track call.
   */
  connectorMultiplicityChosen(properties, options) {
    return this.track(new ConnectorMultiplicityChosen(properties), options);
  }

  /**
   * Create Project Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Create%20Project%20Clicked)
   *
   * User clicked 'Create project' button from one of this place:
   *
   * * Get started page
   *
   *
   *
   *
   * * Projects page
   *
   * * Toolbar button
   *
   *
   *
   *
   * @param {Object} properties The event's properties.
   * @param {'getStartedPage'|'projectsPage'|'toolbar'} properties.createProjectButtonSource Page or element containing 'Create project' button  Exact values for this enum do not matter, they just need to be distinguishable
   * @param {EventOptions} [options] Options for this track call.
   */
  createProjectClicked(properties, options) {
    return this.track(new CreateProjectClicked(properties), options);
  }

  /**
   * Demand Table Overridden
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Demand%20Table%20Overridden)
   *
   * User has overriden something in demand table
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.Demand Table Row Name] Name of row in demand table  For custom rows we'd like to see just 'Custom row' here
   * @param {string} properties.demandTableRowName Name of row in demand table  For custom rows we'd like to see just 'Custom row' here
   * @param {string} [properties.Project Id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  demandTableOverridden(properties, options) {
    return this.track(new DemandTableOverridden(properties), options);
  }

  /**
   * Distortions Visibility Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Distortions%20Visibility%20Changed)
   *
   * User turned on/off showing of stockouts and overstocks in demand tree
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.Checkbox Status] Shows value that was just set in checkbox. Can be used in multiple events
   * @param {boolean} properties.checkboxStatus Shows value that was just set in checkbox. Can be used in multiple events
   * @param {EventOptions} [options] Options for this track call.
   */
  distortionsVisibilityChanged(properties, options) {
    return this.track(new DistortionsVisibilityChanged(properties), options);
  }

  /**
   * Edit Connection Settings Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Edit%20Connection%20Settings%20Button%20Clicked)
   *
   * User clicked 'Edit connection settings' button
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  editConnectionSettingsButtonClicked(options) {
    return this.track(new EditConnectionSettingsButtonClicked(), options);
  }

  /**
   * Example Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Example%20Opened)
   *
   * User opened one of the examples
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.projectName Name of opened project or example
   * @param {EventOptions} [options] Options for this track call.
   */
  exampleOpened(properties, options) {
    return this.track(new ExampleOpened(properties), options);
  }

  /**
   * Forecast Report Imported
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Forecast%20Report%20Imported)
   *
   * User has imported forecast report changes
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.Project Id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  forecastReportImported(properties, options) {
    return this.track(new ForecastReportImported(properties), options);
  }

  /**
   * Forecasting Parameters Overridden
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Forecasting%20Parameters%20Overridden)
   *
   * User has changed some parameters in Forecasting subtab in Demand tab.
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.Forecasting Parameter Name] Name of parameter in Demand Tab > Forecasting subtab
   * @param {string} properties.forecastingParameterName Name of parameter in Demand Tab > Forecasting subtab
   * @param {string} [properties.Project Id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  forecastingParametersOverridden(properties, options) {
    return this.track(new ForecastingParametersOverridden(properties), options);
  }

  /**
   * Inactive Items Visibility Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Inactive%20Items%20Visibility%20Changed)
   *
   * User chose to hide/show inactive items in demand tree
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.Checkbox Status] Shows value that was just set in checkbox. Can be used in multiple events
   * @param {boolean} properties.checkboxStatus Shows value that was just set in checkbox. Can be used in multiple events
   * @param {EventOptions} [options] Options for this track call.
   */
  inactiveItemsVisibilityChanged(properties, options) {
    return this.track(new InactiveItemsVisibilityChanged(properties), options);
  }

  /**
   * Inventory Table Imported
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Inventory%20Table%20Imported)
   *
   * User has imported inventory table parameters
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.Project Id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  inventoryTableImported(properties, options) {
    return this.track(new InventoryTableImported(properties), options);
  }

  /**
   * Inventory Table Overridden
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Inventory%20Table%20Overridden)
   *
   * User has overriden something in inventory table
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.Inventory Table Column] Name of column in inventory table
   * @param {string} properties.inventoryTableColumn Name of column in inventory table
   * @param {string} [properties.Project Id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  inventoryTableOverridden(properties, options) {
    return this.track(new InventoryTableOverridden(properties), options);
  }

  /**
   * Planned orders > Create orders
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Planned%20orders%20%3E%20Create%20orders)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.[Amplitude] Session Replay ID] Property has no description in tracking plan.
   * @param {string} properties.plannedOrdersSubtab Name of subtab on Planned Orders page
   * @param {string} [properties.sl_subTab] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  plannedOrdersCreateOrders(properties, options) {
    return this.track(new PlannedOrdersCreateOrders(properties), options);
  }

  /**
   * Planned Orders Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Planned%20Orders%20Exported)
   *
   * Event to track when planned orders are exported to Excel spreadsheet
   *
   * @param {Object} properties The event's properties.
   * @param {'xlsx'|'csv'} [properties.Export Format] Property shows where some data from Streamline was exported to.  E.g.:  * xlsx file  * csv file  * db
   * @param {'xlsx'|'csv'} properties.exportFormat Property shows where some data from Streamline was exported to.  E.g.:  * xlsx file  * csv file  * db
   * @param {string} [properties.Streamline Page Name] Name of page in Streamline representing what tab or window was opened
   * @param {EventOptions} [options] Options for this track call.
   */
  plannedOrdersExported(properties, options) {
    return this.track(new PlannedOrdersExported(properties), options);
  }

  /**
   * Project Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Project%20Created)
   *
   * User create a project in Streamline
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  projectCreated(options) {
    return this.track(new ProjectCreated(), options);
  }

  /**
   * Project First Import Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Project%20First%20Import%20Clicked)
   *
   * User clicked 'Import' button after finishing setting up project connections
   *
   * After that event project import is started
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  projectFirstImportClicked(options) {
    return this.track(new ProjectFirstImportClicked(), options);
  }

  /**
   * Project reimport
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Project%20reimport)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  projectReimport(options) {
    return this.track(new ProjectReimport(), options);
  }

  /**
   * Project Was Restricted
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Project%20Was%20Restricted)
   *
   * User's project was restricted to 100 planning items.
   *
   * Event should be bind to showing warning about project restriction
   *
   *
   *
   *
   * @param {Object} properties The event's properties.
   * @param {'projectCreation'|'updateData'|'fullReimport'} properties.projectRestrictionReason Which action leads to project restriction
   * @param {EventOptions} [options] Options for this track call.
   */
  projectWasRestricted(properties, options) {
    return this.track(new ProjectWasRestricted(properties), options);
  }

  /**
   * Talk To Expert Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Talk%20To%20Expert%20Button%20Clicked)
   *
   * User clicked button/link 'Talk to Streamline expert'
   *
   * @param {Object} properties The event's properties.
   * @param {'subscriptionPage'|'projectRestrictedWarning'|'upgradeWindow'} properties.talkToExpertButtonSource Page/link/button containing 'Talk to Streamline expert' button
   * @param {EventOptions} [options] Options for this track call.
   */
  talkToExpertButtonClicked(properties, options) {
    return this.track(new TalkToExpertButtonClicked(properties), options);
  }

  /**
   * Tenant Activation Confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Tenant%20Activation%20Confirmed)
   *
   * User clicked 'Confirm' button on 'Please, confirm that you want to activate Streamline for ...' page:
   *
   *
   *
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  tenantActivationConfirmed(options) {
    return this.track(new TenantActivationConfirmed(), options);
  }

  /**
   * Tree Order Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Tree%20Order%20Changed)
   *
   * User changed order of items/locations/channels in demand tree
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.Demand Tree Order] Order of items/locations/channels in demand tree  Possible values:  * By items  * By locations  * By channels  * Item-location-channel  * Item-channel-location  * Location-Item-Channel  * Location-channel-item  * Channel-item-location  * channel-location-item
   * @param {string} properties.demandTreeOrder Order of items/locations/channels in demand tree  Possible values:  * By items  * By locations  * By channels  * Item-location-channel  * Item-channel-location  * Location-Item-Channel  * Location-channel-item  * Channel-item-location  * channel-location-item
   * @param {EventOptions} [options] Options for this track call.
   */
  treeOrderChanged(properties, options) {
    return this.track(new TreeOrderChanged(properties), options);
  }

  /**
   * Upgrade Streamline Window Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/Upgrade%20Streamline%20Window%20Viewed)
   *
   * User was shown 'Upgrade Streamline' modal window
   *
   * @param {Object} properties The event's properties.
   * @param {'automationSettings'|'usersTab'|'createProject'|'addConnection'} properties.upgradeWindowSource Button/page/action leading user to 'Upgrade Streamline' window   Exact values of this enum do not matter, they just need to be distinguishable
   * @param {EventOptions} [options] Options for this track call.
   */
  upgradeStreamlineWindowViewed(properties, options) {
    return this.track(new UpgradeStreamlineWindowViewed(properties), options);
  }

  /**
   * User Logged In
   *
   * [View in Tracking Plan](https://data.amplitude.com/streamlineplan/Streamline/events/main/latest/User%20Logged%20In)
   *
   * User logged in Streamline with their credentials:
   *
   * 1. User chose password when logging for the first time
   *
   * 2. User entered their credentials after being log out
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  userLoggedIn(options) {
    return this.track(new UserLoggedIn(), options);
  }
}

export const ampli = new Ampli();
