import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';
import inTransitionApi from '@/api/reports/inventoryReport/inTransition';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import {
  exportTypeByKey,
  inTransitionKeys,
  requestTypeByKey
} from '@/config/report/inventoryReport/inTransition.config';
import { findCurrentRowIndex } from '@/helpers/shared/slTable';
import { toArray } from '@/helpers/utils/toArray';
import {
  getTableConfigParams,
  getTableConfigStructure,
  sortingParamsAdapter
} from '@/helpers/shared/tableConfig';
import { namespaceByRoute } from '@/config/report';
import { getRouteName } from '@/helpers/shared/router';
import { FilterObject } from '@/helpers/api/adapters';
import statusCodes from '@/config/utils/statusCodes.config';

const initialState = () => ({
  [inTransitionKeys.ARRIVAL]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  [inTransitionKeys.BACKORDER]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  tab: inTransitionKeys.ARRIVAL
});

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_TAB: 'SET_TAB',
  DELETE_ITEM: 'DELETE_ITEM',
  SELECT_ROW: 'SELECT_ROW',
  UPDATE_CELL: 'UPDATE_CELL',
  CREATE_ITEM: 'CREATE_ITEM',
  RESET_STATE: 'RESET_STATE',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG'
};

const state = initialState();

const getters = {
  getCreateData: (state) => (type) => {
    return toArray(state[type]?.table?.headers)?.reduce((acc, { key, name }) => {
      if (!['id', 'enabled', 'delay', 'description', 'delayDays', 'creationType'].includes(key)) {
        acc[key] = name;
      }

      return acc;
    }, {});
  },
  exportType: (state) => exportTypeByKey[state.tab],
  requestType: (state) => requestTypeByKey[state.tab],
  activeFilterId: (_, __, ___, rootGetters) => () => {
    const namespace = namespaceByRoute[getRouteName()];

    return rootGetters[`${namespace}/activeFilterId`];
  },
  isEdited: (state) => {
    return Object.values(inTransitionKeys).some(type => {
      return state[type]?.table?.metadata?.edited;
    });
  }
};

const mutations = {
  [types.SET_TABLE](state, { type, table }) {
    Vue.set(state[type], 'table', table);
  },
  [types.SET_TAB](state, tab) {
    state.tab = tab;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state[state.tab].tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, { type, payload }) {
    Object.assign(state[type].tableConfig, payload);
  },
  [types.SELECT_ROW](state, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, value);
  },
  [types.UPDATE_CELL](state, { row, cellKey, value }) {
    Vue.set(row, cellKey, value);
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setTab({ commit }, tab) {
    commit(types.SET_TAB, tab);
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  updateInTransitionTables({ dispatch }) {
    return Promise.allSettled([
      dispatch('fetchInTransition', inTransitionKeys.ARRIVAL),
      dispatch('fetchInTransition', inTransitionKeys.BACKORDER)
    ]);
  },
  async fetchInTransition({ state, getters, commit }, tab = null) {
    try {
      const activeTab = tab || state.tab;

      const response = await inTransitionApi.getInTransition({
        filterId: getters.activeFilterId(),
        type: requestTypeByKey[activeTab],
        ...getTableConfigParams(state[activeTab].tableConfig)
      });
      const table = response?.data;

      if (!table) {
        return;
      }

      commit(types.SET_TABLE, {
        type: activeTab,
        table: GenericTableAdapter(table)
      });

      commit(types.SET_TABLE_CONFIG, {
        type: activeTab,
        payload: sortingParamsAdapter(table)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchInTransition' });
    }
  },
  exportToXlsx({ getters }) {
    return inTransitionApi.exportInTransitionToXlsx({
      filterId: getters.activeFilterId(),
      type: getters.exportType
    });
  },
  importFromXlsx({ getters }, file) {
    return inTransitionApi.importInTransitionFromXlsx({
      type: getters.exportType
    }, file);
  },
  async updateOverrides({ state, getters }, { id, filterId, ...body }) {
    try {
      await inTransitionApi.updateInTransitionOverrides({
        global: !id,
        id,
        type: getters.requestType,
        ...(!id && {
          query: getTableConfigParams(state[state.tab].tableConfig).query,
          filterId
        })
      }, body);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'inTransition/updateOverrides' });
    }
  },
  async updateVisibleColumns({ getters }, params) {
    try {
      await inTransitionApi.updateVisibleColumns({
        ...params,
        type: getters.requestType
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'plannedOrders/updateVisibleColumns' });
    }
  },
  updateCell({ state, commit }, { value, rowId, cellKey }) {
    const rows = state[state.tab].table.rows;
    const rowIndex = findCurrentRowIndex({
      table: rows,
      rowId,
      key: 'id'
    });

    if (rowIndex !== -1) {
      const oldCell = rows[rowIndex][cellKey];
      const newValue = {
        ...oldCell,
        val: value
      };

      commit(types.UPDATE_CELL, {
        row: rows[rowIndex],
        cellKey,
        value: newValue
      });
    }
  },
  selectRow({ state, commit }, { rowId, cellKey, value }) {
    const rows = state[state.tab].table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    commit(types.SELECT_ROW, {
      cell: rows[rowIndex],
      cellKey,
      value: {
        val: value
      }
    });
  },
  async deleteOrders({ getters }) {
    try {
      await inTransitionApi.deleteOrder({
        id: getters.activeFilterId(),
        type: getters.requestType
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'inTransition/deleteOrder' });
    }
  },
  async createOrder({ getters }, item) {
    try {
      const response = await inTransitionApi.createOrder(
        {
          filterId: getters.activeFilterId(),
          type: getters.requestType
        },
        FilterObject(item)
      );

      if (response.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.CreatedSuccessfully', { 1: i18n.t('Web.PlannedOrders.Order') })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'inTransition/createOrder' });

      throw e;
    }
  },
  async saveOverrides({ dispatch }) {
    try {
      await inTransitionApi.saveInTransition();

      await dispatch('updateInTransitionTables');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'inTransition/saveOrders' });

      throw e;
    }
  },
  async discardOverrides({ dispatch }) {
    try {
      await inTransitionApi.discardInTransitionOverrides();

      await dispatch('updateInTransitionTables');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'inTransition/discardOverrides' });
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
