<template>
  <div
    v-close-popper
    tabindex="0"
    class="sl-tree-search-option body-1 grey-80"
    @click="$emit('select')"
    @keydown.enter="$emit('select')"
  >
    <div class="sl-tree-search__main">
      <icon
        :data="nodeIcon"
        class="size-16 fill-off sl-tree-search__icon"
      />
      <div class="sl-tree-search__content">
        <div class="sl-tree-search__label">
          {{ targetNodeLabel }}
        </div>
        <SlNodePath
          v-if="path.length > 1"
          :path="path"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlTreeSearchOption',
  props: {
    item: {
      type: Object,
      required: true
    },
    ignoreFirstPath: Boolean
  },
  computed: {
    path() {
      return this.item.path.slice(this.ignoreFirstPath ? 1 : 0);
    },
    targetNodeLabel() {
      return this.path.at(-1) || '';
    },
    nodeIcon() {
      return require(`@icons/tree_${this.item.cat ? 'cat' : 'leaf'}.svg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-dropdown/sl-tree-search-option.scss";
</style>